// Quote item type ids
export const AVAILABILITY = 1;
export const EVENT_SUMMARY = 2;
export const MENU = 3;
export const FINAL_SUMMARY = 4;
export const STAFF = 5;
export const GUESTS = 6;

// Menu items
export const PAGE = 1;
export const GROUP_PAGE = 5;
export const PRODUCT_GROUP = 4;
export const NOTE = 3;
export const PRODUCT = 2;
export const FILTER = 6;
export const FILTER_GROUP = 7;
export const A_LA_CARTE_FILTER = 8;
export const LINKED_MENU_LINK = 9;
export const SUB_MENU = 10;
export const SUB_MENU_LIST = 11;
export const SUB_GROUP = 12;

// Event Types
export const CIVIL_CEREMONY = 1;

// Product IDs
export const STAFF_PRODUCT = 659;
