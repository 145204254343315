import React from 'react';
import Week from './Week';

export default function Month({ month, blockedDates, changeDate, hideHeader, selectedDate }) {
  const m = [];
  const startOfMonth = month.clone().startOf('month').startOf('isoWeek');
  const endOfMonth = month.clone().endOf('month').endOf('isoWeek');

  while (startOfMonth.isSameOrBefore(endOfMonth)) {
    m.push(<Week key={`week_${startOfMonth.format('DD-MM-YYYY')}`} weekBeginning={startOfMonth.clone()} changeDate={changeDate} blockedDates={blockedDates} selectedDate={selectedDate} month={month}/>);
    startOfMonth.add(1, 'w');
  }

  return (
    <div className="month">
      { !hideHeader &&
        <div className="month-header text-center">
          <span>{month.format('MMMM YYYY')}</span>
        </div> 
      }
      <div className="week names">
        <span className="day">Mon</span>
        <span className="day">Tue</span>
        <span className="day">Wed</span>
        <span className="day">Thu</span>
        <span className="day">Fri</span>
        <span className="day">Sat</span>
        <span className="day">Sun</span>
      </div>
      {m}
    </div>
  );
}
