import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { SET_EVENT_TYPE } from '../redux/quote';
import PropTypes from 'prop-types';
function EventTypePicker({ dispatch, eventTypes, eventType }) {

  const setEventType = (e) => {
    let type = _.find(eventTypes, { id: parseInt(e.target.value) });
    dispatch({ type: SET_EVENT_TYPE, payload: type });
  }

  if (_.isEmpty(eventTypes)) {
    return null;
  }

  return (
    <React.Fragment>
        <label>Event Type</label>
          <EventTypesDropdown
            types={eventTypes}
            setEventType={(e) => setEventType(e)}
            eventType={(!_.isEmpty(eventType)) ? eventType.id : _.first(eventTypes).id }
          />

    </React.Fragment>
  );
}
EventTypePicker.propTypes = {
  dispatch: PropTypes.func,
  eventTypes: PropTypes.array,
  eventType: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    bookingTypes: state.config.bookingTypes,
    eventType: state.quote.eventType,
    
  };
}

export default connect(mapStateToProps)(EventTypePicker);

function EventTypesDropdown({types, setEventType, eventType}) {
  const typeList = types.map(function (type) {
    return (
      <option key={type.id} value={type.id}>
        {type.fee && type.fee != 0
          ? type.name + ` (+ £${type.fee})`
          : type.name}
      </option>
    );
  });
  return (
    <select
      value={eventType.id}
      onChange={setEventType}
    >
      {typeList}
    </select>
  );
}

EventTypesDropdown.propTypes = {
  types: PropTypes.array,
  setEventType: PropTypes.func,
  eventType: PropTypes.number
}

