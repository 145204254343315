import _ from 'lodash';
export function moneyFormat(price) {
  return (
    '£' +
    Number(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
}
export function getAllChildItems(item, menu) {
  let items = _.filter(menu, function (i) {
    return i._lft >= item._lft && i._rgt <= item._rgt;
  });
  return _.orderBy(items, '_lft');
}

export function getAllItemsInAGroup(item, menu) {
  let parent = getParent(item, menu);
  return getAllChildItems(parent, menu);
}

export function getParent(item, menu) {
  return _.find(menu, { id: item.parent_id });
}
export function getSameLevelItems(item, menu, additional) {
  return _.filter(menu, { parent_id: item.parent_id, ...additional});
}
