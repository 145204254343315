import dotProp from 'dot-prop-immutable';
import _ from 'lodash';
import {
  getParent,
  getAllChildItems,
} from '../services/product';
import {
  chooseMenu, updateMenu
} from './actions/rules';
import {SUB_MENU, SUB_MENU_LIST} from "../constants/MenuItemTypes";

export const LOAD_QUOTE = 'LOAD_QUOTE';
export const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM';
export const SET_EVENT_TYPE = 'SET_EVENT_TYPE';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_GUEST_AMOUNT = 'SET_GUEST_AMOUNT';
export const TOGGLE_PRODUCT = 'TOGGLE_PRODUCT';
export const SET_QUANTITY = 'SET_QUANTITY';
export const SET_URL_DATE = 'SET_URL_DATE';
export const SET_CATERING_MINIMUM_SPEND = 'SET_CATERING_MINIMUM_SPEND';

export const SET_GUESTS = 'SET_GUESTS';
export const OPEN_MENU = 'OPEN_MENU';
export const REMOVE_MENU = 'REMOVE_MENU';
export const SELECT_MENU = 'SELECT_MENU';
export const STASH_MENU = 'STASH_MENU';
export const UPDATE_MENU_ITEM = 'UPDATE_MENU_ITEM';
export const SELECT_PRODUCT = 'SELECT_PRODUCT'
export const UPDATE_CHOICES = 'UPDATE_CHOICES';
export const UPDATE_QUANTITIES = 'UPDATE_QUANTITIES';

export const HIGHLIGHT_SUBMENU_ITEMS = 'HIGHLIGHT_SUBMENU_ITEMS'
export const SELECT_COMPULSORY_ITEMS = 'SELECT_COMPULSORY_ITEMS'

export function quoteReducer(
  state = {
    menu: {},
    eventType: '',
    activeMenuItem: '',
    selectedDate: {},
    guests: {},
    urlDate: {},
    cateringMinimumSpend: 0.0,
    minimum: 0.0,
    stashedMenus: {},
  },
  action,
) {
  switch (action.type) {
    case LOAD_QUOTE: {

      return { ...state, menu: action.payload};
    }
    case SET_EVENT_TYPE: {
      return { ...state, eventType: action.payload };
    }
    case SET_GUESTS: {

      return { ...state, guests: action.payload };
    }
    case HIGHLIGHT_SUBMENU_ITEMS: {

        let menu = { ...state.menu };

        _.each(menu, (item) => {

          if([SUB_MENU, SUB_MENU_LIST].includes(item.menu_item_type_id)) {
            getAllChildItems(item, menu).forEach((i) => {
                menu[i.id].is_submenu = true;
            });
          }
        });
      return dotProp.set(state, 'menu', menu);
    }
    case SELECT_COMPULSORY_ITEMS: {
        let menu = { ...state.menu };
      _.each(menu, (item) => {
        if(item.is_compulsory === 1 && !item.is_submenu) {
          item.selected = true;
        }
      });

      return dotProp.set(state, 'menu', menu);
    }
    case UPDATE_QUANTITIES: {
      let menu = { ...state.menu };
      let guests = { ...state.guests };
      _.each(menu, (item) => {
        menu = updateMenu(item, menu, guests);
      });
      return dotProp.set(state, 'menu', menu);
    }
    case SET_SELECTED_DATE: {
      return {
        ...state,
        selectedDate: action.payload,
      };
    }
    case UPDATE_MENU_ITEM: {
      const { item } = action.payload;
      return dotProp.set(state, `menu.${item.id}`, item);
    }
    case SET_QUANTITY: {
      const { item, quantity } = action.payload;
      let i = { ...item };
      i.quantity = quantity;
      i.total = _.multiply(quantity, item.unit_price);

      return dotProp.set(
        state,
        `menu.${item.id}`,
        i,
      );
    }
    case SET_URL_DATE: {
      return {
        ...state,
        urlDate: action.payload,
        selectedDate: {date: action.payload},
      };
    }
    case SET_CATERING_MINIMUM_SPEND: {
      return {
        ...state,
        cateringMinimumSpend: action.payload.catering_minimum_spend,
        minimum: action.payload.minimum,
      }
    }
    case SELECT_MENU: {
      let menu = { ...state.menu };
      return dotProp.set(state, 'menu', menu);
    }
    case STASH_MENU: {
      let stashedMenus = state.stashedMenus;
      stashedMenus[action.payload.menuId] = action.payload.currentMenu;
      return {
        ...state,
        stashedMenus,
      };
    }
    case OPEN_MENU: {
      let menu = { ...state.menu };
      let guests = { ...state.guests };
      const { item } = action.payload;


      _.each(getAllChildItems(item, menu), (i) => {
        if(item.id !== i.id) {
          menu[i.id].submenu = item.id;
        }

      });

      menu = chooseMenu(item, menu, guests);

      return dotProp.set(state, 'menu', menu);
    }
    case REMOVE_MENU: {
      const menu = { ...state.menu };
      const { item } = action.payload;
      menu[item.id].selected = false;
      _.each(getAllChildItems(item, menu), (i) => {
        // Hide all items
        menu[i.id].selected = false;
      });
      const parent = getParent(item, menu);
      menu[parent.id].selected = false;
      return dotProp.set(state, 'menu', menu);
    }
    case SET_GUEST_AMOUNT: {
      return dotProp.set(
        state,
        `guests.${action.payload.id}.total`,
        parseInt(action.payload.value),
      );
    }
    case SELECT_PRODUCT: {
      let { item } = action.payload;
      return dotProp.set(state,`menu.${item.id}`, {...item, selected: !item.selected});
    }
    case UPDATE_CHOICES: {
      let { menu } = action.payload;
      return dotProp.set(state, 'menu', menu);
    }
    default:
      return state;
  }
}
