import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import axios from 'axios';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/pro-light-svg-icons';
import PriceModal from './PriceModal';
import Month from './Month';
import {SET_SELECTED_DATE} from '../../redux/quote';
import {updateCateringMinimumSpend} from "../../redux/actions/rules";


const moment = extendMoment(Moment);

function SingleCalendar({ dispatch, config, quote }) {
  const [loading, setLoading] = useState(false);
  const [blockedDates, setBlockedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(_.isEmpty(quote.urlDate) ? moment() : quote.urlDate);
  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState(moment());

  const defaultSelectedDateIfBlocked = (blockedDates) => {
    if (blockedDates[selectedDate.format('DD-MM-YYYY')]) {
      let newDate = moment();
      // It's possible today's date might also be blocked, so loop until we find a non-blocked day
      while(blockedDates[newDate.format('DD-MM-YYYY')]) {
        newDate = newDate.add(1, 'days');
      }

      dispatch({
        type: SET_SELECTED_DATE,
        payload: { date: newDate },
      });
    }
  };

  const getAvailabilityData = () => {
    setLoading(true);
    axios
      .get(`${config.appUrl}/api/v2/availability/${config.location.id}`)
      .then((response) => {
        setBlockedDates(response.data);
        defaultSelectedDateIfBlocked(response.data);

        if(!_.isEmpty(quote.urlDate) && !config.hidePrice) {
          changeDate(selectedDate);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    getAvailabilityData();
    if (config.startDate) {
      const configStart = moment(config.startDate, "DD-MM-YYYY");
      setSelectedDate(configStart);
      setStartDate(configStart);
    }
  },[]);

  const changeDate = (date) => {

    const d = date.add(1,'h'); // override the GMT (DST) issue

    setSelectedDate(date);

    axios
      .get(`${config.appUrl}/api/location/price?location_id=${config.app_id}&hire_from=${date.format('DD-MM-YYYY')}`,
        {
          headers: {
            Accept: 'application/json',
          },
        })
      .then((response) => {
        const price = (!config.hidePricing) ? response.data.venue : 0;
        dispatch({
          type: SET_SELECTED_DATE,
          payload: {
            date: d,
            price: price,
          },
        });

        dispatch(updateCateringMinimumSpend());
      });

  };

  const previous = () => {
    if (selectedDate.isAfter(startDate)) {
      setSelectedDate(selectedDate.clone().subtract(1, 'month'));
    }
  };

  const next = () => {
    setSelectedDate(selectedDate.clone().add(1, 'month'));
  };

  return (
    <div id="cordero-availability-calendar">
      <div className="row navigation">
        <div className="col-2">
          <button type="button" onClick={() => previous()} disabled={selectedDate.isBefore(config.startDate)}>
            <FontAwesomeIcon icon={faArrowLeft} size="2x" />
          </button>
        </div>
        <div className="col-8 text-center">
          <h5>{(!_.isEmpty(selectedDate)) ? moment(selectedDate).format('MMMM YYYY') : moment().format('MMMM YYYY')}</h5>
        </div>
        <div className="col-2 text-end">
          <button type="button" onClick={() => next()}
            disabled={
                config.maxDisplayYear &&
                selectedDate.month() == 11 &&
                selectedDate.year() == config.maxDisplayYear
                }>
            <FontAwesomeIcon icon={faArrowRight} size="2x" />
          </button>
        </div>
      </div>
      <div>
        <div>
          <div className="calendar">
            {!loading && <Month
              month={selectedDate}
              selectedDate={selectedDate}
              blockedDates={blockedDates}
              changeDate={(e) => changeDate(e)}
              hideHeader
            />}
            {loading && (
              <div className="loading-container">
                <div>
                  <FontAwesomeIcon icon={faCircleNotch} size="4x" spin />
                </div>
                <h4>Loading Availability</h4>
              </div>
            )}
          </div>
          { (!_.isUndefined(quote.selectedDate.price) && quote.selectedDate.price != 0 && !config.hidePricing) &&
            <div className="text-center">
              <h5>The price for your chosen date is</h5>
              <h4>£{ quote.selectedDate.price.toFixed(2)}</h4>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ config: state.config, quote: state.quote });

export default connect(mapStateToProps)(SingleCalendar);
