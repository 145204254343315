import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ItemType from './ItemType';
import PropTypes from 'prop-types';

function Menu({ quote }) {
  let first = _.find(quote.menu, { _lft: 1 });

  return (
    <React.Fragment>
      <div className="Menu">
        <ItemType item={first} />
      </div>
    </React.Fragment>
  );
}

Menu.propTypes = {
  quote: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    quote: state.quote,
  };
}

export default connect(mapStateToProps)(Menu);
