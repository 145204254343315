import React from 'react';
import {
  Col, Row, Container, Jumbotron, Button,
} from 'react-bootstrap';

function Home() {
  return (
    <Container>
      <Row>
        <Col>
          <Jumbotron className="home text-center">
            <img src="https://app.cordero.cloud/images/cordero.png" className="my-4" alt="cordero" />
            <p>
              This website is for quotes provided by
              <a href="https://cordero.cloud">
                Cordero Cloud
              </a>
              ,
              <br />
              If you are having trouble accessing your quote please contact our support team.
            </p>
            <p>
              <Button href="mailto:support@cordero.cloud" variant="primary">Contact Us</Button>
            </p>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
}
export default Home;
