import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Menu from './Menu';
import PropTypes from 'prop-types';

function Main({quote}) {
  let item = _.find(quote.menu, { _lft: 1 });

    if (item) {
      return (
        <div className="Main">
          <Menu key={'Menu-' + item.id} item={item} />
        </div>
      );
    } else {
      return null;
    }
}

Main.propTypes = {
  quote: PropTypes.object
}


const mapStateToProps = (state) => {
  return {
    quote: state.quote,
  };
}

export default connect(mapStateToProps)(Main);
