import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ItemType from '../ItemType';
import PropTypes from 'prop-types'; 
function Page({ quote, item }) {
  let items = _.orderBy(
    _.filter(quote.menu, { parent_id: item.id }),
    '_lft',
  );

  return (
    <div id={'MenuItem' + item.id} className='Page'>
      <div className="row">
        <div className="col">
          {items.map((item) => (
            <ItemType key={item.id} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    quote: state.quote,
  };
};

Page.propTypes = {
  item: PropTypes.object,
  quote: PropTypes.object
};

export default connect(mapStateToProps)(Page);
