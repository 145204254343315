import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import EventTypePicker from './EventTypePicker';
import GuestTypes from './GuestTypes';
import SingleCalendar from './Calendar/SingleCalendar';

function QuoteIntro({config, quote}) {

  const [showModal, setShowModal] = useState(false);

  let imageUrl = 'none';
  if (_.isEmpty(config)) {
    return null;
  }
  if (!_.isNull(config.location.image_id)) {
    imageUrl =
      'url(' +
      config.appUrl +
      '/quote/image/' +
     config.location.id +
      ')';
  }

  useEffect(() => {
    setShowModal(true);
  },[]);

  return (
    <React.Fragment>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className={'event-details-modal'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className={'modal-text'}>
          <h3>Change Quote Details</h3>
          <SingleCalendar />
          <EventTypePicker eventTypes={config.bookingTypes} eventType={quote.eventType}/>
          <h5>Guest Numbers</h5>
          <GuestTypes />
          <div className="row">
            <div className="col">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                onClick={() => setShowModal(false)}
              >
                Start Quote
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className={'quote-intro-container'}>
        <div className={'container'}>
          <div className="row">
            <div className="col-10">
              <h2 className={'quote-heading'}>Create your event quote</h2>
            </div>
            <div className="col-2">
              <a
                style={{ display: 'inherit' }}
                onClick={() => setShowModal(true)}
                className={'btn btn-primary'}
              >
                <small>Change Details </small>
                <i className={'far fa-pencil'}></i>
              </a>
            </div>
          </div>

          <div className={'row quote-intro-row'}>
            <div className={'col-8 py-4 event-details-col'}>
              <div className={'col-12'}>
                <h2 className={'event-details-header'}>
                  {config.location.name}
                </h2>
              </div>

              <div className={'col-auto mr-1'}>
                <div className={'event-detail-text'}>
                  {moment(quote.selectedDate.date).format(
                    'ddd Do MMMM YYYY',
                  )}
                </div>
                <div className={'event-detail-text pl-3'}>
                  {quote.eventType.name}
                </div>
                <div className={'row'}>
                  <div className="col event-detail-text">
                    <ul className={'list-inline'}>
                      {_.map(quote.guests, (item) => {
                        return (
                          <li key={item.id} className="list-inline-item">
                            {item.total} {item.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {/* <EventDetails /> */}
              <div className={'quote-intro-description'}></div>
            </div>
            <div className={'col-4 location-image-col'}>
              <div
                style={{ backgroundImage: imageUrl, height: '100%' }}
                className={'location-image'}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}

QuoteIntro.propTypes = {
  config: PropTypes.object,
  quote: PropTypes.object
}

function mapStateToProps(state) {
  return {
    quote: state.quote,
    config: state.config,
  };
}

export default connect(mapStateToProps)(QuoteIntro);
