import React from 'react';
import PropTypes from 'prop-types';
import {
  NOTE,
  PAGE,
  PRODUCT,
  PRODUCT_GROUP,
  SUB_MENU,
  SUB_MENU_LIST,
} from '../constants/MenuItemTypes';

import SubMenu from './blocks/SubMenu';
import Page from './blocks/Page';
import ProductGroup from './blocks/ProductGroup';
import Product from './blocks/Product';
import Note from './blocks/Note';

function ItemType(props) {
  let item = props.item;
  switch (item.menu_item_type_id) {
    case PAGE:
      return <Page key={'Page-' + item.id} item={item} />;
    case PRODUCT_GROUP:
      return <ProductGroup key={'ProductGroup-' + item.id} item={item} />;
    case SUB_MENU:
    case SUB_MENU_LIST:
      return <SubMenu key={'SubMenu-' + item.id} item={item} />;
    case NOTE:
      return <Note key={'Note-' + item.id} item={item} />;
    case PRODUCT:
      return <Product key={'Product-' + item.id} item={item} />;
    default:
      return null;
  }
}
ItemType.propTypes = {
  item: PropTypes.object
}

export default ItemType;
