import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {SET_GUEST_AMOUNT, UPDATE_QUANTITIES} from '../redux/quote';
import GuestDropdown from './GuestDropdown';
import {updateCateringMinimumSpend} from "../redux/actions/rules";

function GuestTypes({ guests, dispatch, config }) {
  const setGuests = (e) => {
    dispatch({
      type: SET_GUEST_AMOUNT,
      payload: { value: e.target.value, id: e.target.name },
    });
    dispatch({type: UPDATE_QUANTITIES });
    dispatch(updateCateringMinimumSpend());
  };

  if (_.isEmpty(guests)) {
    return null;
  }

  return (
    <div className="guest-row">
      {guests && _.map(_.orderBy(guests,'sort_order'), (guest) => (
          <div key={guest.id} className="guest-col">
            <label htmlFor={`guest${guest.id}`}>{guest.name}</label>
            <GuestDropdown
              min={(!_.isNull(guest.minimum)) ? guest.minimum : config.location.capacity_min}
              max={(!_.isNull(guest.maximum)) ? guest.maximum : config.location.capacity_max}
              value={guest.total}
              name={guest.id}
              id={`guest${guest.id}`}
              setGuests={(e) => setGuests(e)}
            />
          </div>
        ))
      }
    </div>
  );
}

GuestTypes.propTypes = {
  guests: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    guests: state.quote.guests,
    config: state.config,
  };
}

export default connect(mapStateToProps)(GuestTypes);
