import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const defaultProps = {
    placement: 'right',
    color: 'navy'
};

function CustomTooltip({placement, color, text, children}) {
   
    return (
        <OverlayTrigger placement={placement}
                        delay={{show: 10, hide: 200}}
                        overlay={<Tooltip id={`tooltip-${placement}`}
                                    className={`tooltip-${color}`}>
                            {text}
                        </Tooltip>}>
            {children}
        </OverlayTrigger>
    );
}

CustomTooltip.propTypes = {
    placement: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.element,
};

CustomTooltip.defaultProps = defaultProps;

export default (CustomTooltip);