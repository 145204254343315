import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import { appReducer } from './app';
import { quoteReducer } from './quote';
import LogRocket from 'logrocket';

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}))
  || compose;

const store = createStore(
  combineReducers({
    quote: quoteReducer,
    config: appReducer,
  }),
  {},
  composeEnhancers(applyMiddleware(LogRocket.reduxMiddleware(), thunk)),
);

export default store;
