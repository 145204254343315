import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';
import { connect } from 'react-redux';
import axios from 'axios';
import GuestTypes from "../GuestTypes";
import EventTypePicker from "../EventTypePicker";

function PriceModal({config, quote, showModal, closeModal, priceLoading, children}) {
  const [inputs, setInput] = useState({
    first_name: '',
    last_name: '',
    email: '',
    contact_number: '',
    location: 0,
  });
  const [enquirySent, setEnquirySent] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = { ...inputs };

    formData.event_date = quote.selectedDate.date.format('DD-MM-YYYY');
    formData.guests = quote.guests;
    formData.eventType = quote.eventType;
    formData.key = config.key;
    formData.app_id = 1;

    axios
      .post(config.appUrl + `/api/v2/lead`, formData, {
        headers: {
          AccessControlAllowOrigin: '*',
          Accept: 'application/json',
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // Success
          setEnquirySent(true);
        }
      })
      .catch((err) => {
        let errors = err.response.data.errors;
        setErrors(errors);
      });
  }

  const redirectToWebsite= () => {
    window.open(config.quoteBaseUrl + (quote.selectedDate.date ? '/' + quote.selectedDate.date.format('DD-MM-YYYY') : ''), '_blank', 'noreferrer');
  }

  const handleUpdate = (e) => {
    setInput({ ...inputs, [e.target.name]: e.target.value });
  };


  return (
    <Modal
      show={showModal}
      className="availability-price-modal"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => closeModal()}
      centered
      animation={false}
      bsPrefix="cordero-modal"
    >
      <Modal.Header>
        <button
          type="button"
          className="close"
          onClick={() => closeModal()}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-container">
          <div className="price-container text-center">
            {quote.selectedDate.date &&
              <h1 className="price-text">
                 {quote.selectedDate.date.format('Do MMMM YYYY')}
              </h1>
            }
            {(priceLoading && (
              <>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  size="3x"
                  spin
                  className="hire-price mt-3"
                />
              </>
            )) ||
              (!priceLoading && !config.hidePricing && (
                <div className="price">
                  <h1 className="price-text">The hire price  is <span className="hire-price">
                    {quote.selectedDate.price !== 0  ? `£${quote.selectedDate.price}` : 'Call for a price'}
                  </span></h1>
                </div>
              ))}
          </div>

          {!config.hasQuote &&
            config.hasForm &&
            !config.enquirySent && (
              <form className="text-left" id="corderoEnquiryForm">
                <h5 className="text-center">
                  Please enter your details below to submit your enquiry
                </h5>
                <div className="form-inputs">
                  <div className="left-column">
                    <label>First Name</label>
                    <input
                        type="text"
                        name="first_name"
                        value={inputs.first_name}
                        onChange={(e) => handleUpdate(e)}
                    />
                    <label>Email</label>
                    <input
                        type="text"
                        name="email"
                        value={inputs.email}
                        onChange={(e) => handleUpdate(e)}
                    />
                    <EventTypePicker eventTypes={config.bookingTypes} eventType={quote.eventType}/>

                  </div>
                  <div className="right-column">
                    <label>Last Name</label>
                    <input
                        type="text"
                        name="last_name"
                        value={inputs.last_name}
                        onChange={(e) => handleUpdate(e)}
                    />
                    <label>Contact Number</label>
                    <input
                        type="text"
                        name="contact_number"
                        value={inputs.contact_number}
                        onChange={(e) => handleUpdate(e)}
                    />
                    <GuestTypes />
                  </div>
                </div>
              </form>
            )}
        </div>
        <div className="submit-btn-container">
        {!config.hasQuote &&
          config.hasForm &&
          enquirySent && (
            <div >
              <h4>{config.enquirySentText}</h4>
            </div>
          )}
        {!enquirySent && (
          <div >
            <div className="quote-btn">
              {config.hasQuote && quote.selectedDate.price !== 0 && (
                <button
                  className="submit-btn"
                  onClick={(e) => redirectToWebsite(e)}
                  title="Proceed to Quote"
                >
                  Proceed to Quote
                </button>
              )}

              {!config.hasQuote && config.hasForm && (
                <button
                  to="#"
                  onClick={(e) => handleSubmit(e)}
                  className="submit-btn"
                  title="Submit Enquiry"
                >
                  Submit Enquiry
                </button>
              )}
            </div>
          </div>
        )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({ config: state.config, quote: state.quote });

export default connect(mapStateToProps)(PriceModal);