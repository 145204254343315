import React from 'react';
import Day from './Day';

export default function Week({ changeDate, weekBeginning, blockedDates, selectedDate, month }) {
  const startOfWeek = weekBeginning.clone();
  const endOfWeek = weekBeginning.clone().endOf('isoWeek');
  const days = [];

  while (startOfWeek.isSameOrBefore(endOfWeek)) {
    days.push(<Day key={`day_${startOfWeek.format('DD-MM-YYYY')}`} day={startOfWeek.clone()} changeDate={changeDate} blockedDates={blockedDates} selectedDate={selectedDate} month={month} />);
    startOfWeek.add(1, 'days');
  }

  return (
    <div className="week">
      {days}
    </div>
  );
}
