import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import LogRocket from 'logrocket';

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('ly7hqd/myevent');
}
const quote = document.getElementById('cordero-quote');
// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<App />, quote);
