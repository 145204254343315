import React from 'react';
import PropTypes from 'prop-types';

function GuestDropdown({
  setGuests, name, value, min, max,
}) {
  const setOptions = () => {
    const options = [];
    for (let i = min; i <= max; i += 1) {
      options.push(
        <option value={i} key={`${name}_${i}`}>
          {i}
        </option>,
      );
    }
    return options;
  };

  return (
    <select
      onChange={setGuests}
      name={name}
      value={value}
    >
      {setOptions()}
    </select>
  );
}
GuestDropdown.propTypes = {
  setGuests: PropTypes.func.isRequired,
  name: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default GuestDropdown;
