import _ from 'lodash';
import {
  getAllChildItems,
  getSameLevelItems,
} from '../../services/product';
import {PAGE, PRODUCT, PRODUCT_GROUP} from '../../constants/MenuItemTypes';
import axios from "axios";
import {SET_CATERING_MINIMUM_SPEND} from "../quote";
import moment from "moment";


export function getIncludedGuests(guests, exclusions) {

  const includedGuests = _.filter(guests, (g) => {
    return !exclusions.includes(g.id);
  });

  return _.sumBy(Object.values(includedGuests), 'total');
}

const maximumProductChoices = (item, menu, rules) => {
  let currentChoices = getSameLevelItems(item, menu, { selected: true });
  if(rules.maximum_choices === 1) {
    _.each(currentChoices, (i) => {
    if(i.id !== item.id) {
        menu[i.id].selected = false;
      }
    });
    return menu;
  }
  if ((currentChoices.length >= rules.maximum_choices) && currentChoices.length !== 0) {
    _.each(getSameLevelItems(item, menu, { selected: false }), (i) => {
      if(i.id !== item.id) {
        menu[i.id].is_disabled.push('maximum group limit');
      }
    });
  }
  else {
    _.each(_.filter(getSameLevelItems(item, menu), (e) => (e.is_disabled.length > 0)), (i) => {
      menu[i.id].is_disabled = _.pull(menu[i.id].is_disabled, 'maximum group limit');
    });
  }
  return menu;
}

export const setItemSelected = (item, menu, guests, rules, distributeCount, addRemainder) => {
  distributeCount = distributeCount || 1;
  addRemainder = addRemainder || false;
  let includedGuests = getIncludedGuests(guests, rules.exclusions);
  let quantity = Math.floor( includedGuests / distributeCount);
  if (addRemainder) {
    quantity += includedGuests % distributeCount;
  }
  quantity = item.per_person ? Math.floor(_.multiply(quantity, rules.guest_multiplier)) : quantity;

  if (item.show_quantity_picker && item.quantity > 0) {
    quantity = item.quantity;
  }

  quantity = (menu[item.id].minimum_quantity !== null)
      ? menu[item.id].minimum_quantity
      : quantity;

  quantity = (menu[item.id].maximum_quantity !== null && menu[item.id].maximum_quantity < quantity)
      ? menu[item.id].maximum_quantity
      : quantity;

  menu[item.id].quantity = quantity;
  menu[item.id].selected = true;
  menu[item.id].total = _.multiply(item.unit_price, quantity);

  if (item.menu_item_type_id === PRODUCT && menu[item.parent_id] && menu[item.parent_id].menu_item_type_id === PRODUCT_GROUP) {
    menu[item.parent_id].selected = true;
  }

  if (item.menu_item_type_id !== PRODUCT_GROUP) {
    let childProducts = _.filter(getAllChildItems(item, menu), (menuItem) => {
      return menuItem.id !== item.id && menuItem.menu_item_type_id === PRODUCT;
    })

    _.forEach(childProducts, (childProduct) => {
      if (childProduct.is_compulsory === 1) {
        menu = setItemSelected(childProduct, menu, guests, getRules(childProduct, menu));
      }
      menu = distributeQuantities(childProduct, menu, guests);
    });
  }

  return menu;
}

export const selectedChildProducts = (item, menu) => {
  return _.filter(getAllChildItems(item, menu), (menuItem) => {
    return menuItem.id !== item.id && menuItem.menu_item_type_id === PRODUCT && menuItem.selected === true;
  })
}

export const setItemUnSelected = (item, menu) => {
  menu[item.id].quantity = 0;
  menu[item.id].total = 0;
  menu[item.id].selected = false;

  let childProducts = _.filter(getAllChildItems(item, menu), (menuItem) => {
    return menuItem.id !== item.id && menuItem.menu_item_type_id === PRODUCT;
  })

  _.forEach(childProducts, (childProduct) => {
    menu = setItemUnSelected(menu[childProduct.id], menu);
  });

  return menu;
}

export const getRules = (item, menu, rules) => {
  rules = rules || {
    guest_multiplier: 1,
    exclusions: [],
    distribute_ids: [],
    distribute_selected: [],
  };

  if (item.parent_id) {
    rules = getRules(menu[item.parent_id], menu, rules);
  }

  if (item.distribute_quantity === 1) {
    const children = _.filter(getAllChildItems(item, menu), (menuItem) => {
      return menuItem.menu_item_type_id === PRODUCT;
    });

    rules.distribute_ids = [];
    _.forEach(children, (child) => {
      rules.distribute_ids.push(child.id);

      if (child.selected === true) {
        rules.distribute_selected.push(child.id);
      }
    });
  }

  if (item.exclusions.length > 0) {
    rules.exclusions = rules.exclusions.concat(item.exclusions);
  }

  if (item.guest_multiplier !== null) {
    rules.guest_multiplier = item.guest_multiplier;
  }

  if (item.distribute_quantity > 0) {
    rules.distribute_quantity_from_id = item.id;
  }

  return rules;
}

export const distributeQuantities = (item, menu, guests) => {
  const rules = getRules(item, menu);

  if (rules.distribute_ids.includes(item.id)) {
    _.forEach(rules.distribute_ids, (id, index) => {
      if (menu[id].selected === true) {
        menu = setItemSelected(menu[id], menu, guests, rules, rules.distribute_selected.length, index === 0);
      } else {
        menu = setItemUnSelected(menu[id], menu);
      }
    });
  }

  return menu;
}

const checkMaximumChoices = (item, menu) => {
  const parent = menu[item.parent_id] || null;
  let maximumChoices = parent && parent.maximum_choices ? parent.maximum_choices : null;

  if (maximumChoices === null) {
    return menu;
  }

  const siblings = getAllChildItems(parent, menu).filter((i) => {
    return i.id !== parent.id && i.depth === parent.depth + 1;
  });

  if (maximumChoices === 1) {
    _.forEach(siblings, (sibling) => {
      menu = setItemUnSelected(sibling, menu);
    });

    return menu;
  }

  const selectedSiblings = siblings.filter((sibling) => {
    return sibling.selected === true;
  });

  if (selectedSiblings.length > maximumChoices) {
    const error = new Error('Only ' + maximumChoices + ' are allowed here');
    error.item_id = parent.id;
    throw error;
  }

  return menu;
}

export function updateCateringMinimumSpend() {
  return function (dispatch, getState) {

    const config = getState().config;
    const quote = getState().quote;
    const date = quote.selectedDate.date || moment();
    const selected = _.filter(quote.menu, 'selected');


    const mapped = selected.map((i) => {
      if(i.total > 0) {
        return {
          product_id: i.product_id,
          total: i.total,
        }
      }

    });
    mapped.push({
      product_id: 12098,
      total: quote.selectedDate.price,
    });



    axios
        .post(`${config.appUrl}/api/location/cateringMinimumSpend?location_id=${config.app_id}&hire_from=${date.format('DD-MM-YYYY')}`,
            {selected:mapped},
            {
              headers: {
                Accept: 'application/json',
              },
            })
        .then((response) => {
          dispatch({
            type: SET_CATERING_MINIMUM_SPEND,
            payload: response.data,
          });
        });
  }
}


export const updateMenu = (item, menu, guests) => {
  if (item.menu_item_type_id === PAGE) {
    return menu;
  }

  let rules = getRules(item, menu);

  if (item.selected || selectedChildProducts(item, menu).length > 0) {
    try {
      menu = checkMaximumChoices(item, menu);
      menu = setItemSelected(item, menu, guests, rules);
    } catch (e) {
      menu = setItemUnSelected(item, menu);
    }
  } else {
    menu = setItemUnSelected(item, menu);
  }

  menu = distributeQuantities(item, menu, guests);

  return menu;
}

export const chooseMenu = (item, menu, guests) => {
  let rules = getRules(item, menu);


    try {
      menu = checkMaximumChoices(item, menu);
      menu = setItemSelected(item, menu, guests, rules);
    } catch (e) {
      menu = setItemUnSelected(item, menu);
    }


  menu = distributeQuantities(item, menu, guests);
  return menu;
}

export function toggleProduct(item) {

  return function (dispatch, getState) {
    let menu = getState().quote.menu;

    menu[item.id].selected = !item.selected;
    menu = updateMenu(item, menu, getState().quote.guests)

    dispatch({type: 'UPDATE_CHOICES', payload: { menu }});
    dispatch(updateCateringMinimumSpend());
  }
}

export function getMenuId(menu) {
  const keys = menu ? Object.keys(menu) : [];
  if (keys.length === 0) return null;
  return menu[keys[0]].menu_id || null;
}
