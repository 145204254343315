import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { moneyFormat } from '../services/product';
import MenuSummary from './MenuSummary';
import {
  SUB_MENU,
  SUB_MENU_LIST,
  PRODUCT_GROUP,
} from '../constants/MenuItemTypes';
import SubmitQuote from './SubmitQuote';
import {OverlayTrigger, Popover} from "react-bootstrap";

function Sidebar({ quote, config }) {
  const selectedItems = _.orderBy(
    _.filter(quote.menu, { selected: true, submenu: null }),
    '_lft',
  );

  const venueHireCost = (!_.isUndefined(quote.selectedDate.price)) ? quote.selectedDate.price : 0;
  const eventType = quote.eventType;

  const menuCost = (menu) => _.sumBy(_.filter(menu, { selected: true }),
    (i) => {
      return i.total;
    },
  );

  const fee = (!_.isNull(eventType.fee)) ? parseInt(eventType.fee, 10) : 0;
  const total = [menuCost(quote.menu), venueHireCost, fee];

  const minimumSpendPopover = (
      <Popover id="popover-positioned-scrolling-top" title="Minimum Spend Information" className="p-3">
        <p>The minimum spend displayed has been calculated based on the date you are looking to hire.</p>
        {/*<p>Your venue hire and any event type fees will not be included in the total.</p>*/}
        <p>If you have any questions or would like some advice on how to meet the minimum spend,
          please feel free to give us a call.</p>
      </Popover>
  );

  return (
    <div>
      <div className="Sidebar" id="Sidebar">
        <div className="final-summary-btn-container">
          <h5>Quote summary</h5>
          <div className="row no-gutters mt-3 light-shadow">
            <div className="col">
              <SubmitQuote />
            </div>
          </div>
        </div>
        <div className="row collapse-summary">
          <div className="col">
            <div className="sidebar-content">
              {_.isEmpty(selectedItems) && _.isUndefined(quote.selectedDate.price) ? (
                <div className="text-center">
                  You currently have no items selected
                </div>
              ) : (
                ''
              )}
              {venueHireCost > 0
                && (
                  <>
                    <div className="sidebar-group">
                      <b>Hire Cost</b>
                    </div>
                    <div className="sidebar-item">
                      Venue Hire
                      <span className="float-end">
                        {moneyFormat(venueHireCost)}
                      </span>
                    </div>
                  </>
                )}
              {eventType.fee > 0
                && (
                  <>
                    <div className="sidebar-group">
                      <b>Event Fees</b>
                    </div>
                    <div className="sidebar-item">
                      {eventType.name}
                      <span className="float-end">
                        {moneyFormat(eventType.fee)}
                      </span>
                    </div>
                  </>
                )}
              {!_.isEmpty(selectedItems)
              && _.map(selectedItems, (i) => {
                if (
                  i.menu_item_type_id === SUB_MENU
                  || i.menu_item_type_id === SUB_MENU_LIST
                ) {
                  return (
                    <MenuSummary
                      key={i.id}
                      item={i}
                      menu={quote.menu}
                      guests={quote.guests}
                    />
                  );
                }
                return (
                  <div key={i.id} className="sidebar-item">
                    {(i.menu_item_type_id !== PRODUCT_GROUP)
                    && (
                      <div>
                        {`${i.quantity} x ${i.label}`}
                        <span className="float-end">
                          {moneyFormat(i.quantity * i.unit_price)}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
              {config.minimumSpendVisibleOnSidebar && 
              <div className={"sidebar-item mt-3" }>
                  Minimum Spend ({moneyFormat(quote.minimum / 100)}) Catering Spend &nbsp;
                 <OverlayTrigger
                      container={this}
                     trigger="click"
                     placement="right"
                     overlay={minimumSpendPopover}
                     rootClose
                  >
                    <a href="#" onClick={(e) => { e.preventDefault();}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           className="bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                       <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                      </svg>
                    </a>
                  </OverlayTrigger>
                  <span className="float-end">
                   {moneyFormat(quote.cateringMinimumSpend)}
                  </span>
                </div>
              }
            </div>
            <div className="sidebar-footer">
              <div className="row grand-total">
                <div className="col-auto pr-0">
                  <h4>Total</h4>
                </div>
                <div className="col text-right">
                  <h4>
                    {moneyFormat(_.sum(total))}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-small-print">
        <sup>*</sup>
        All prices are inclusive of VAT
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ quote: state.quote, config: state.config });
Sidebar.propTypes = {
  quote: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Sidebar);
