import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import {connect} from "react-redux";

function Day({day, selectedDate, changeDate, blockedDates, month, branding }) {

  if(_.isUndefined(branding)) {
    return null;
  }

  const customBranding = JSON.parse(branding);

  const classes = ['day'];


  const dayHighlight = (day, styles) => {
    let key = null;

    if (day.format('M') !== month.format('M')) {
      return {
        background: '#fff',
        color: '#fff'
      }
    }
    if(!_.isUndefined(blockedDates[day.format('DD-MM-YYYY')])) {
      key = blockedDates[day.format('DD-MM-YYYY')];
    }
    if(day.format('DD-MM-YYYY') === selectedDate.format('DD-MM-YYYY')) {
      key = 'selected'
    }

    if(day.format('DD-MM-YYYY') === moment().format('DD-MM-YYYY') && day.format('M') !== month.format('M')) {
      key = 'current';
    }


    if(!_.isUndefined(customBranding)) {
      if(!_.isNull(key)) {
        const isStriped = (customBranding[key].striped === "true") ? `repeating-linear-gradient(-45deg, ${customBranding[key].style.background}, ${customBranding[key].style.background} 5px,#fff 5px, #fff 10px)` : customBranding[key].style.background;

        return {color: customBranding[key].style.color, background: isStriped}
      }

      }
    return {backgroundColor: '#dfecfb', color: '#245382'}

  }
  const isBlocked = (customBranding, key) => {


    if(!_.isUndefined(customBranding[key])) {
      if(!_.isUndefined(customBranding[key].blocked)) {
        return true;
      }

    }

    return false;

  }
  return (
    <span className={classes.join(' ')} style={dayHighlight(day, customBranding)}
        onClick={() => (!isBlocked(customBranding, blockedDates[day.format('DD-MM-YYYY')])) ? changeDate(day) : '' }
    >
      {(!day.format('M') !== month.format('M')) ? day.format('D'): ''}
    </span>
  );
}

const mapStateToProps = (state) => ({branding: state.config.branding});

export default connect(mapStateToProps)(Day);
