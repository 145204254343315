import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PAGE, PRODUCT, PRODUCT_GROUP } from '../../constants/MenuItemTypes';
import { getAllChildItems } from '../../services/product';

function ListView({ item, quote }) {
  const items = _.orderBy(getAllChildItems(item, quote.menu), '_lft');

  return (
    <>
      <div>
        <ul className="list-unstyled list-view">
          {items
          && _.map(_.sortBy(items, '_lft'), (i) => {
            switch (i.menu_item_type_id) {
              case PAGE:
                return (
                  <li
                    key={`listView${i.id}`}
                    className="mt-3"
                  >
                    <h4 className="mb-0">{i.label}</h4>
                  </li>
                );
              case PRODUCT_GROUP:
                return (
                  <li
                    key={`listView${i.id}`}
                    className="mt-3"
                  >
                    <h5 className="mb-0">{i.label}</h5>
                  </li>
                );
              case PRODUCT:
                return (
                  <li key={`listView${i.id}`}>
                    {`-${i.label}`}
                    <div className="pl-3 w-100 font-italic small">
                      {i.description}
                    </div>
                  </li>
                );
              default:
                return null;
            }
          })}
        </ul>
      </div>
    </>
  );
}

ListView.propTypes = {
  item: PropTypes.object.isRequired,
  quote: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ quote: state.quote });

export default connect(mapStateToProps)(ListView);
