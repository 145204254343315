import ReactGA from "react-ga4";

export const SET_APP_CONFIG = 'SET_APP_CONFIG';
export const GA_EVENT = 'GA_EVENT';
export const GA_PAGEVIEW = 'GA_PAGEVIEW';

export function appReducer(state = {}, action) {
  switch (action.type) {
    case SET_APP_CONFIG:
      return action.payload;
    default:
      return state;
  }
}
