import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ItemType from '../ItemType';
import { getAllChildItems } from '../../services/product';
import PropTypes from 'prop-types';

function ProductGroup({ item, quote }) {
  let items = _.orderBy(
    _.filter(quote.menu, { parent_id: item.id }),
    '_lft',
  );
  let selected = _(getAllChildItems(item, quote.menu))
    .filter((f)=> { return f.selected === true && item.id !== f.id})
    .filter(function (i) { return i.depth === item.depth + 1; })
    .size();

  return (
    <div id={'MenuItem' + item.id}>
      <div className="row">
        <div className="col">
          <div className="float-start">
            <h2 className="product-group-title">
              {item.label}
              {(!_.isNull(item.minimum_choices) && item.minimum_choices > 0) ? <span className="required"> - {`${item.minimum_choices} item${item.minimum_choices > 1 ? 's':''} required`}</span> :''}
              </h2>
            <div className={'product-group-description'}>
              {item.description}
            </div>
          </div>
          <div className="float-end">
            {item.maximum_choices && (
              <span className="badge float-right  badge-secondary">
                {selected} / {item.maximum_choices}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={'product-group'}>
        {items.map((item) => (
          <ItemType key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}
const mapStateToProps = function (state) {
  return {
    quote: state.quote,
  };
};

ProductGroup.propTypes = {
  item: PropTypes.object,
  quote: PropTypes.object
};

export default connect(mapStateToProps)(ProductGroup);
