import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import store from '../redux/store';
import QuoteWrapper from './QuoteWrapper';
import Quote from '../pages/Quote';
import Home from '../pages/Home';


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/:slug/:date?">
            <QuoteWrapper>
              <Quote />
            </QuoteWrapper>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
