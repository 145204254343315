import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {moneyFormat} from '../../services/product';
import QuantityPicker from '../QuantityPicker';
import PropTypes from 'prop-types'; 
import {toggleProduct} from '../../redux/actions/rules';
function Product({item, config, dispatch}) {
  const chooseItem = (item) => {
    if(!_.isEmpty(item.is_disabled) || item.is_compulsory === 1) {
      return;
    }
    
    dispatch(toggleProduct(item));
    
  };

  const selected = item.selected  ? 'selected' : '';
  const price = (config.showFullPrice) ? item.total : item.unit_price;

  if(item.selected && item.show_quantity_picker) {
    return (
      <div className={'blockCard ' + ' ' + selected}>
        <div>
          <div className="block-title row">
            <div className="col-9"><span className="float-start"><QuantityPicker item={item}/></span> {item.label}</div>
            <div className="col-3 text-end">{(price > 0) ? `+ ${moneyFormat(price)}`:''}</div> 
          </div>
          {item.description && 
            <div className="row">
              <div className="col-12">
                <div className="block-description pl-2">{item.description}</div>
              </div>
            </div>
          }
        </div>
      </div>
    
    );
  }

  return (
    <div className={'blockCard ' + ' ' + selected}>
      <div onClick={() => chooseItem(item)}>
        <div className="block-title row">
          <div className="col-9"> {(item.quantity > 0 && item.selected) ? `${item.quantity} x` : ''} {item.label}</div>
          <div className="col-3 text-end">{(price > 0) ? `+ ${moneyFormat(price)}`:''}</div> 
        </div>
        {item.description && 
          <div className="row">
            <div className="col-12">
              <div className="block-description pl-2">{item.description}</div>
            </div>
          </div>
        }
      </div>

    </div>
  
  );
}

Product.propTypes = {
  item: PropTypes.object,
  quote: PropTypes.object,
  dispatch:PropTypes.func
};


const mapStateToProps = function (state) {
  return {
    quote: state.quote,
    config: state.config
  };
};

export default connect(mapStateToProps)(Product);
