import React from 'react';
import { faMinus, faPlus, faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {toggleProduct, updateCateringMinimumSpend} from '../redux/actions/rules';

function QuantityPicker({ item, dispatch }) {

  let stepProductQuantity = (type) => {
    let quantity = item.quantity;

    if (type === 'addition') {
      quantity++;
    } else {
      if ( item.quantity >= item.minimum_quantity) {
        quantity--;
      }
    }
    dispatch({
      type: 'SET_QUANTITY',
      payload: { item: item, quantity: quantity },
    });



  };
  let changeValue = (e) => {

    dispatch({
      type: 'SET_QUANTITY',
      payload: { item: item, quantity: e.target.value },
    });

    dispatch(updateCateringMinimumSpend());
  };

  const removeProduct = (item) => {
    console.log(item);
    dispatch(toggleProduct(item));
  }
  return (
    <div className="QuantityPicker">
      {(item.quantity > item.minimum_quantity) &&
        <div className={`item-btn float-start`} onClick={() => stepProductQuantity('subtract')}>
          <FontAwesomeIcon icon={faMinus} />
        </div>
      }

      {(item.quantity === 0 || item.quantity >= item.minimum_quantity) &&
        <div className={`item-btn float-start`} onClick={() => removeProduct(item)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      }
      <input
        type="numeric"
        value={item.quantity}
        className="product-quantity text-center float-start"
        onChange={(e) => changeValue(e)}
      />
      <div className={'item-btn float-end'} onClick={() => stepProductQuantity('addition')}>
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </div>
  );
}

QuantityPicker.propTypes = {
  item: PropTypes.object,
  dispatch: PropTypes.func
}

const mapStateToProps = function (state) {
  return {
    quote: state.quote,
  };
};

export default connect(mapStateToProps)(QuantityPicker);
